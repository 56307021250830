const logUserMixins = {
  data() {
    return {
      userId: null,
      interval: null,
      current_user_log: null,
      logRef: null,
      authData: null,
      sessionId: this.$route.params.sessionId || 'central',
      currentView: 0,
    }
  },
  // beforeDestroy() {
  //   // this.setViewStatusNull()
  // },
  async created() {
    this.authData = JSON.parse(
      localStorage[`auth:live-tube-portal:${this.$route.params.projectSlug}`]
    )
    this.userId = this.authData.username

    let sessionId = this.$route.params.sessionId
    if (sessionId !== undefined) {
      const userStatusDatabaseRef = this.$rdb.ref(
        `view-status/${sessionId}/${this.userId}`
      )
      const isOfflineForDatabase = {
        state: 'offline',
        last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
      }
      const isOnlineForDatabase = {
        state: 'online',
        last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
      }
      await this.$rdb.ref('.info/connected').on('value', function (snapshot) {
        if (snapshot.val() === false) {
          return
        }
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(function () {
            userStatusDatabaseRef.set(isOnlineForDatabase)
          })
      })
    }

    let startTime = new Date().getTime()

    this.logRef = this.$rdb
      .ref('logs/' + this.$route.params.projectSlug + '/' + this.sessionId)
      .push()

    this.logRef.set({
      email: this.authData.email,
      clock: {
        in: startTime,
        out: startTime,
      },
    })

    this.interval = setInterval(() => {
      const newTime = new Date().getTime()
      this.logRef.set(
        {
          email: this.authData.email,
          clock: {
            in: startTime,
            out: newTime,
          },
        },
        (error) => {
          if (error) {
            // The write failed...
          } else {
            // Data saved successfully!
          }
        }
      )
    }, 30000)

    if (this.authData) {
      this.$rdb
        .ref(
          'view-status/' +
            this.$route.params.projectSlug +
            '/' +
            this.sessionId +
            '/' +
            localStorage.uuid
        )
        .set({ status: 'online' })
    }
    this.$ga.set({ userId: this.authData.email })
  },
  destroyed() {
    this.$rdb.ref('view-status/' + this.sessionId + '/' + this.userId).set({
      state: 'offline',
      last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
    })
    clearInterval(this.interval)
  },
}

export default logUserMixins
