<template>
  <div id="live-session" class="section-agenda">
    <a-layout-content style="padding: 0 50px">
      <a-row>
        <a-col
          :lg="{ span: 20, offset: 2 }"
          :md="{ span: 24, offset: 0 }"
          :xs="{ span: 24, offset: 0 }"
        >
          <h2 class="agenda-title">Agenda</h2>
          <h3 class="agenda-description">Thailand Time</h3>
          <a-row>
            <a-col
              :lg="{ span: 8, offset: 3 }"
              :md="{ span: 10, offset: 1 }"
              :xs="{ span: 24, offset: 0 }"
            >
              <div class="agenda-day">
                <div class="day">DAY1</div>
                <div class="date">
                  <span class="number">07</span>
                  <span class="desc">WED<br />OCT, 2020</span>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>
            </a-col>
            <a-col
              :lg="{ span: 8, offset: 3 }"
              :md="{ span: 10, offset: 2 }"
              :xs="{ span: 24, offset: 0 }"
            >
              <div class="agenda-day">
                <div class="day">DAY2</div>
                <div class="date">
                  <span class="number">08</span>
                  <span class="desc">THU<br />OCT, 2020</span>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>

              <div class="agenda-row">
                <div class="time">
                  <h3>09:00 - 09:05</h3>
                </div>
                <div class="title">
                  <h3>Event start &amp; welcome</h3>
                  <h4>Nisarg Mehta</h4>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import logUser from '@/mixins/logUser'
import loginCheck from '@/mixins/loginCheck'
import customCssMixins from '@/mixins/customCss.js'

dayjs.extend(relativeTime)
dayjs.extend(isBetween)
export default {
  name: 'Agenda',
  components: {},
  mixins: [logUser, loginCheck, customCssMixins],
  metaInfo() {
    return {
      title: `${this.sessionName} - ${this.projectName}`,
      titleTemplate: '%s - LiveTube Portal',
    }
  },
  data() {
    return {
      isDesktop: window.matchMedia('(min-width: 768px)').matches,
      player: null,
      now: dayjs(),
      session: {},
      project: [],
      projectName: '',
      projectSlug: '',
      modalImage: '',
      visible: false,
      confirmLoading: false,
    }
  },
  computed: {
    sessionName() {
      return this.session.session_name
    },
    sessionDesc() {
      return this.session.session_desc
    },
    sessionStatus() {
      const session = this.session
      if (session) {
        if ('start_time' in session) {
          const startTime = dayjs(session.start_time.toDate())
          const endTime = dayjs(session.end_time.toDate())

          if (this.now.isBefore(startTime)) {
            return 1
          }

          if (this.now.isBetween(startTime, endTime)) {
            return 0
          }

          if (this.now.isAfter(endTime)) {
            return 2
          }
        } else {
          return false
        }
      }
      return false
    },
  },
  async created() {
    await this.detectLogin({
      name: 'Login',
      params: { projectSlug: this.$route.params.projectSlug },
    })

    await this.$binding(
      'project',
      this.$db
        .collection('project')
        .where('project_slug', '==', this.$route.params.projectSlug)
    )

    if (this.project[0]) {
      this.projectName = this.project[0].name
      this.projectSlug = this.project[0].project_slug
      this.$store.commit('changeMutation', this.project[0].logo)
    }

    await this.$binding(
      'session',
      this.$db.collection('sessions').doc(this.$route.params.sessionId)
    )
    if (this.session.player_type !== 'iframe') {
      this.player = window.OvenPlayer.create('video', {
        autoStart: true,
        sources: [
          {
            file: this.session.video_url,
          },
        ],
      })
    }
  },
  methods: {
    relativeTime(time) {
      dayjs.locale(this.$i18n.locale)
      return dayjs().to(time.toDate())
    },
    showModalDay1() {
      this.modalImage = require('@/assets/img/day1.jpg')
      this.visible = true
    },
    showModalDay2() {
      this.modalImage = require('@/assets/img/day2.jpg')
      this.visible = true
    },
    showModalDay3() {
      this.modalImage = require('@/assets/img/day3.jpg')
      this.visible = true
    },
  },
}
</script>

<style lang="scss">
.section-agenda {
  padding-bottom: 60px;
}
.desktop-header {
  margin-top: 24px;
}
.agenda-title {
  text-align: center;
  font-size: 2.4em;
  font-weight: 700;
  line-height: 1em;
  color: #fff;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 60%);
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}
.agenda-description {
  margin-bottom: 0;
  text-align: center;
  font-weight: 700;
  line-height: 1em;
  color: #fff;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 60%);
}
.agenda-day {
  margin-top: 30px;
  background-color: #212b1c;
  color: #fff;
  text-align: center;
  .day {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    line-height: 70px;
    font-weight: 400;
  }
  .date {
    display: inline-block;
    text-align: left;
    span {
      display: inline-block;
    }
    .number {
      display: inline-block;
      margin-right: 5px;
      font-size: 3em;
      font-weight: 700;
    }
    .desc {
      line-height: 1.4em;
    }
  }
}
.agenda-row {
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 15fr 40fr;
  .time {
    position: relative;
    h3 {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      margin-top: 15px;
      padding: 5px;
      background-color: #d8e8f2;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      color: #333;
      z-index: 100;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 45px;
      width: 1px;
      height: calc(100% - 3px);
      background-color: #fff;
      z-index: 0;
    }
  }
  .title {
    min-height: 140px;
    padding: 15px;
    background-color: #fff;
    h3 {
      margin-bottom: 20px;
      font-weight: 700;
    }
    h4 {
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 768px) {
  #live-session {
    padding-top: 15px;
  }
  .ant-divider {
    display: none;
  }
}
</style>
