const loginCheckMixins = {
  methods: {
    detectLogin(path) {
      return new Promise((resolve) => {
        this.$auth.onAuthStateChanged((user) => {
          if (!user) {
            this.$router.push(path)
          } else {
            resolve(user)
          }
        })
      })
    },
  },
}

export default loginCheckMixins
